import React from 'react';
import './index.css';
import { Grid, TextField, Button, AppBar, IconButton } from '@mui/material';
import { Fingerprint,Visibility,VisibilityOff,AccountCircle } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import {Card,Typography,CardMedia,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,InputAdornment} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EmailIcon from '@mui/icons-material/Email';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import * as ETVConstants from './ETVConstants';

class Login extends React.Component {
    constructor(props) {
      super(props);
      document.title = "elevator.tv"
    }

    state={
      userNameOK:true,
      passwordOK:true,
      window:'',
      show_overlay:false,
      show_login_div:true,
      info_message_title:'Error',
      info_message:'',
      send_pw_addr:'',
      email_helper_ok:true,
      cur_pw: '',
      new_pw1: '',
      new_pw1_helper_ok:true,
      new_pw2: '',
      new_pw2_helper_ok:true,
      username:'',
      password:'',
      login_btn_active: true,
      send_email_helper_ok:true,
      cur_pw_helper_ok:true,
      reset_pw1: '',
      reset_pw2: '',
      reset_pw2_helper_ok:true,
      status: undefined,
      children:[],
      otp:'',
      error_reason:0,
      show_password:false,
    }

    loginFunc() {
      this.setState({login_btn_active:false});
      var username = this.state.username;
      var password = this.state.password;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({action: 'login', username:username, password:password })
      };
      var url = ETVConstants.getServerBase()+'/useradmin';
      //console.log("url="+url);
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(JSON.stringify(result));
          this.setState({login_btn_active:true});
          if(!result["user_exists"]) {
            this.setState({userNameOK:false,username:'',password:''})
            return;
          }

          if(!result['active']) {
            this.setState({show_login_div:false});
            var parent = result['parent'];
            this.showInfoMessage("Account Inactive","Please contact your administrator ("+parent['user_name']+")");
            return;
          }

          if(!result['activation']) {
            this.setState({show_login_div:false});
            var parent1 = result['parent'];
            this.showInfoMessage("Account not activated","Please activate your account or contact your administrator ("+parent1['user_name']+")");
            return;
          }

          this.setState({userNameOK:true});

          if(!result["password_ok"]) {
            this.setState({password:'',passwordOK:false});
            return;
          }

          if(result['need_pw_update']) {
            this.setState({user_id:result['user_id'],access_token:result["access_token"]});
            this.showWindow("forced_pw_change_div",true);
            return;
          }

          //// all ok
          if(result["otp_requested"]) {
            this.showWindow("otp_div",true);
            this.setState({user_id:result['user_id']});
            return;

          }else{
            // all ok
            var roles = result["roles"];
            //console.log("roles="+roles);
            var status = result["status"];
            var accessToken = result["access_token"];
            var children = result["children"];
            //console.log("children="+children);

            //console.log("---> 123 user="+JSON.stringify(result));
            localStorage.setItem("etv.access_token",accessToken);
            localStorage.setItem("etv.user_id",result['user_id']);
            localStorage.setItem("etv.user_name",result['username']);
            //console.log("-------------------->roles="+roles+" status="+status);
            this.setState({passwordOK:true, children:children, roles: roles, status:status, username:'',password:''});
            //console.log("---->11234 Logging in, userID="+result['user_id']+" username="+result['username']+" lang="+result['lang']);
            this.props.loggedInCallback(true, result['user_id'], result['username'], roles, children, status, result['lang']);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //console.log("xxxx error login err="+JSON.stringify(error));
          this.setState({login_btn_active:true});
        }
      )

    };

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(title,text) {
      this.setState({info_message_title:title,info_message:text});
      this.showWindow('message_div',true);
    }

    closeInfoMessage() {
      this.setState({info_message:undefined, show_login_div:true});
      this.showWindow('message_div',false);
    }

    showResetPW() {
      this.showWindow('reset_password_div',true);
    }

    showForgotPW() {
      this.showWindow("forgot_password_div",true);
    }

    sendPW() {
      //console.log("sending pw to "+this.state.send_pw_addr);
      this.showInfoMessage('Password','Reset Link sent to user');
    }

    isEmail( emailAddr) {
      const EMAIL_REGEX =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      return EMAIL_REGEX.test(emailAddr);
    }

    sendPWChange(username, curPW, newPW) {
        const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({action: 'change_password', cur_pw: curPW, username:username, password:newPW })
        };
        fetch(ETVConstants.getServerBase()+'/useradmin', requestOptions)
            .then((response) => response.json())
            .then((responseJson) => {
                        //console.log("----> changePW result="+JSON.stringify(responseJson));
                if(!responseJson["result"]) {
                if(responseJson["reason"]==='user_does_not_exist') {
                    this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("user_does_not_exist"));
                    return;
                }
                this.setState({cur_pw_helper_ok:false});
                return;
                }
                this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("password_successfully_changed"));
            })
            .catch((error) => {
                        this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans(error));
            });
	}

    resetPW() {
      //console.log("reset PW, email="+this.state.username+" cur="+this.state.cur_pw+" new="+this.state.new_pw1+" repeat="+this.state.new_pw2);
      this.setState({email_helper_ok:true, new_pw1_helper_ok:true, new_pw2_helper_ok:true});
      if(!this.isEmail(this.state.username)) {
        this.setState({email_helper_ok:false});
        return;
      }
      if(!this.state.new_pw1) {
        this.setState({new_pw1_helper_ok:false});
        return;
      }
      if(!this.state.new_pw2) {
        this.setState({new_pw2_helper_ok:false});
        return;
      }
      if(this.state.new_pw2!==this.state.new_pw1) {
        this.setState({new_pw2_helper_ok:false});
        return;
      }
      this.sendPWChange(this.state.username, this.state.cur_pw, this.state.new_pw1);
    }

    checkResendPW() {
        this.setState({send_email_helper_ok:true});
        if(!this.isEmail(this.state.send_pw_addr)) {
          this.setState({send_email_helper_ok:false});
          return;
        }
        this.resendPW(this.state.send_pw_addr);
    }

    resendPW(email) {
      // is not logged in!
      var url = ETVConstants.getServerBase()+'/useradmin?action=send_password&username='+email;
      fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(!responseJson['result']) {
          //console.log(JSON.stringify(responseJson));
          this.showInfoMessage(ETVConstants.trans('error'),ETVConstants.trans(responseJson['reason']));
          return;
        }
        this.showInfoMessage(ETVConstants.trans('success'),ETVConstants.trans("email_was_sent"));
         //this.showWindow('info_message_div',false);
         //this.setState({info_message:'successfully changed password'});
      })
      .catch((error) => {
         this.showInfoMessage(ETVConstants.trans('error'),"Error: "+error);
      });
    }

    getPaymentInfoLink() {
      var urlStr = window.location.href;
      if (urlStr.indexOf("payment_info=")>0) {
        var info = urlStr.substring(urlStr.indexOf("payment_info=")+"payment_info".length+1,urlStr.length);
        return info;
      }else{
        return undefined;
      }
    }

    getResetPWLink() {
      var urlStr = window.location.href;
      if (urlStr.indexOf("reset_id=")>0) {
        var resetID = urlStr.substring(urlStr.indexOf("reset_id=")+"reset_id".length+1,urlStr.length);
        return resetID;
      }else{
        return undefined;
      }
    }

    changeUserPW( resetID) {
       if(this.state.reset_pw1!==this.state.reset_pw2) {
         this.setState({reset_pw2_helper:"password mismatch"});
         return;
       }
       this.resetPWServer(resetID,this.state.reset_pw1);
    }

    resetPWServer(resetID,pw) {
      this.showInfoMessage(ETVConstants.trans("reset"),"sending reset password");
      var url = ETVConstants.getServerBase()+"/useradmin?action=reset_pw&reset_id="+resetID+"&password="+pw;
      fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(!responseJson['result']) {
          this.showInfoMessage('Error',responseJson['reason']);
          return;
        }
        window.location.href="/";
      })
      .catch((error) => {
         this.showInfoMessage('Info',"Error: "+error);
      });
    }

    enforceNewPw() {
      var url = ETVConstants.getServerBase()+"/useradmin?action=change_password&user_id="+this.state.user_id+"&password="+this.state.new_pw1;
      fetch(url,{ headers: { 'user_id':this.state.user_id, 'access_token': this.state.access_token }})
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(JSON.stringify(responseJson));
        if(!responseJson['result']) {
          if(responseJson['reason']==='same_password') {
            this.setState({new_pw1_helper_ok:false, error_reason:1});
            this.setState({new_pw2:''});
            return;
          }
          if(responseJson['reason']==='not_secure_password') {
            this.setState({new_pw1_helper_ok:false, error_reason:2});
            this.setState({new_pw2:''});
            return;
          }
        }
        this.showInfoMessage(ETVConstants.trans('success'),ETVConstants.trans("password_successfully_changed"));
        window.location.href="/";
      })
      .catch((error) => {
         this.showInfoMessage('Info',"Error: "+error);
      });
    }

    submitOTP(otp) {
      var url = ETVConstants.getServerBase()+'/useradmin?action=validate_otp&user_id='+this.state.user_id+"&otp="+this.state.otp;
      //console.log(url);
      fetch(url)
      .then((response) => response.json())
      .then((result) => {
        //console.log("otp result="+JSON.stringify(result));
        var validOTP = result["valid_otp"];
        if(validOTP) {
            var roles = result["roles"];
            var status = result["status"];
            var accessToken = result["access_token"];
            //console.log("---> access_token="+accessToken);
            localStorage.setItem("etv.access_token",accessToken);
            localStorage.setItem("etv.user_id",result['user_id']);
            //console.log("-------------------->roles="+roles+" status="+status);
            this.setState({passwordOK:true, roles: roles, status:status, username:'',password:''});
            //console.log("---->Logging in, userID="+result['user_id']+" username="+result['username']+" lang1="+result["lang"]);
            this.props.loggedInCallback(true, result['user_id'], result['username'], roles, status, result["lang"]);
        }else{
          this.showInfoMessage(ETVConstants.trans("error"),"Error: invalid OTP");
        }
      })
      .catch((error) => {
         this.showInfoMessage(ETVConstants.trans("error"),"Error: "+error);
      });
    }

    gotoLogin() {
      window.location.href = window.location.origin;
    }

    isOnPremise( domain) {
      var temp = domain.trim().toLowerCase();
      if(temp === 'localhost') return true;
      if(temp.startsWith('10.')) return true;
      if(temp.startsWith('172.')) return true;
      if(temp.startsWith('127.')) return true;
      if(temp.startsWith('192.168.')) return true;
      return false;
    }

    getLogo( domain) {
        if(ETVConstants.ON_PREMISE_VERSION) return "/images/elevator_onpremise.jpg";
        if(!domain || domain.trim().toLowerCase().endsWith('elevator.tv')) return "/images/logo.jpg";
        if(domain.trim().toLowerCase().endsWith('emch.com')) return "/images/emch_logo.png";
        if(domain.trim().toLowerCase().endsWith('lift.tv')) return "/images/lifttvlogo.png";
        if(domain.trim().toLowerCase().endsWith('nle.jp')) return "/images/nlelogo.png";
        //default
        return "/images/logo.jpg";
    }

    getAddress( domain) {
        if(!domain || domain.trim().toLowerCase().endsWith('elevator.tv')) return String.fromCharCode(169)+" elevator.tv GmbH 2024 | Birkenstrasse 47 | CH-6343 Rotkreuz | Switzerland | https://www.elevator.tv";
        if(domain.trim().toLowerCase().endsWith('emch.com')) return String.fromCharCode(169)+" Emch Aufzüge AG, Fellerstrasse 23, 3027 Bern, Switzerland";
        if(domain.trim().toLowerCase() === 'localhost') return String.fromCharCode(169)+" elevator.tv GmbH 2024 | Birkenstrasse 47 | CH-6343 Rotkreuz | Switzerland ";
        if(domain.trim().toLowerCase().endsWith('lift.tv')) return String.fromCharCode(169)+" lift.tv | elevator.tv GmbH | Birkenstrasse 47 | CH-6343 Rotkreuz | Switzerland | https://www.lift.tv";
        if(domain.trim().toLowerCase().endsWith('nle.jp')) return String.fromCharCode(169)+" Nippon Lifts Engineering | https://www.nle.jp";
        // default
        return String.fromCharCode(169)+" elevator.tv GmbH 2024 | Birkenstrasse 47 | CH-6343 Rotkreuz | Switzerland | https://www.elevator.tv";
    }

    isValidEmail( email) {
      if(!email) return false;
      if(email.trim().length===0) return false;
  		return ETVConstants.EMAIL_REGEX.test(email);
    }

    receiveBackupServerInfo( res ) {
      //console.log("receiveBackupServerInfo="+res);
      var isRunningAsMaster = res.running_as_master;
      var serverAddr = res.server_address;
      //console.log("isRunningAsMaster="+isRunningAsMaster+" serverAddr="+serverAddr);
      if(!isRunningAsMaster) {
        var forwardURL = "http://"+serverAddr;
        //console.log("forwardURL="+forwardURL);
        //window.location.href=forwardURL;
        return;
      }
    }

    render() {
        if(ETVConstants.ON_PREMISE_VERSION) {
          //check if this is the backup server and active or not.
          //if not active, then redirect to the server
          var url = ETVConstants.getServerBase()+"/backup_servlet_slave?action=info";
          //console.log("url to check="+url);
          ETVConstants.sendCMDToServerListener(url, this.receiveBackupServerInfo);
        }

        var domain = window.location.hostname;
        var paymentinfo = this.getPaymentInfoLink();
        var resetID = this.getResetPWLink();
        //console.log("domain="+domain);
        //console.log("window.location.href="+window.location.href);

        return (
          paymentinfo?
            <div style={{margin:'auto', marginTop:300, width:300, height: 300}}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="subtitle1">
                  Payment {paymentinfo==='success'?"success":"failed"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary" variant='outlined' onClick={() => {this.gotoLogin()}}>ok</Button>
                </CardActions>
              </Card>
            </div>
          :resetID?
            <div style={{margin:'auto', marginTop:300, width:300, height: 300}}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="subtitle1">{ETVConstants.trans("reset_password")}</Typography>
                    <TextField style={{marginTop:20}} id="pw1" error={false} label={ETVConstants.trans("password")} type="password" fullWidth autoFocus required onChange={(e)=>this.setState({reset_pw1:e.target.value})}/>
                    <TextField style={{marginTop:20}} id="pw2" error={this.state.reset_pw2_helper} helperText={this.state.reset_pw2_helper} label={ETVConstants.trans("repeat_password")} type="password" fullWidth required onInput={(e)=>{this.setState({reset_pw2_helper:''})}} onChange={(e)=>this.setState({reset_pw2:e.target.value})} /><br/><br/>
                    <Button fullWidth disabled={this.state.reset_pw1.trim().length===0 || this.state.reset_pw2.trim().length===0} endIcon={<ChangeCircleIcon/>} color="success" variant='contained' onClick={() => this.changeUserPW(resetID)}>{ETVConstants.trans("reset_password")}</Button>
                  </CardContent>
                </Card>
            </div>
          :
          <div>
          {this.state.show_login_div===true?
            <div id='login_div'>
                <Card elevation={5} style={{padding:'30px'}}>
                    <CardMedia
                        style={{width:'100%',height:'100%'}}
                        image={this.getLogo(domain)}
                        component='img'
                    />
                    <CardContent>
                        <TextField style={{marginTop:10}} id="username" required autoFocus value={this.state.username}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                                ),
                            }}     
                            disabled={!this.state.login_btn_active}
                            error={!this.state.userNameOK}
                            helperText={this.state.userNameOK?'':ETVConstants.trans("user_does_not_exist")}
                            label={ETVConstants.trans("username")}
                            type="email"
                            InputLabelProps={{shrink: true,}}
                            onInput={(e)=>{this.setState({username:e.target.value,userNameOK:true})}}
                            fullWidth={true}
                            sx={{ backgroundColor: 'white' }}
                            variant="outlined" />

                        <TextField style={{marginTop:15}} id="password" required autoFocus value={this.state.password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Fingerprint />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!this.state.login_btn_active}
                            error={!this.state.passwordOK}
                            helperText={this.state.passwordOK?'':ETVConstants.trans("wrong_password")}
                            label={ETVConstants.trans("password")}
                            type={this.state.show_password?"text":"password"}
                            onInput={(e)=>{this.setState({password:e.target.value,passwordOK:true})}}
                            fullWidth={true}
                            sx={{ backgroundColor: 'white' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon/>
                                    </InputAdornment>
                                ),
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                                aria-label={ETVConstants.trans("show_password")}
                                                onClick={(event)=>{this.setState({show_password:this.state.show_password?false:true})}}
                                                edge="end">
                                                    {this.state.show_password ? <VisibilityOff/> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                            variant="outlined" />

                            <Button variant="contained" 
                                    disabled={!this.state.login_btn_active || !this.state.username || this.state.username.trim().length===0 || !this.state.password || this.state.password.trim().length===0 }
                                    color="success"
                                    size="large"
                                    startIcon={<LockIcon />}
                                    fullWidth 
                                    onClick={()=>this.loginFunc()}
                                    sx={{ mt: 2 }}>{ETVConstants.trans("login")}</Button>
                    </CardContent>
                    <CardActions>
                      {ETVConstants.ON_PREMISE_VERSION?<span/>:<Button size='small' color="primary" onClick={() => this.showForgotPW()}>{ETVConstants.trans("forgot_password")}</Button>}
                      <Button size='small' color="primary" onClick={() => this.showResetPW()}>{ETVConstants.trans("reset_password")}</Button>
                    </CardActions>
                  </Card>
              </div>
              :
              <span/>
            }

            <Dialog
              open={this.state.window==='message_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{this.closeInfoMessage()}}>
              <DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.info_message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.window==='otp_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}>
              <DialogTitle id="otp_dialog_title">{ETVConstants.trans("one_time_password")}</DialogTitle>
              <DialogContent>
                    <TextField style={{marginTop:20}} id="otp_field" label={ETVConstants.trans("otp_password")} fullWidth autoFocus required onChange={(e)=>this.setState({otp:e.target.value})}/>
                    <Button style={{marginTop:10}} disabled={this.state.otp.trim().length!==6} onClick={()=>{this.submitOTP()}} fullWidth color="success" variant='contained'>{ETVConstants.trans("submit")}</Button>
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={()=>{this.showWindow('otp_div',false)}} color="primary">{ETVConstants.trans("cancel")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.window==='reset_password_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}>
              <DialogTitle id="reset_pw_dialog_title">{ETVConstants.trans("reset_password")}</DialogTitle>
              <DialogContent>
                    <TextField style={{marginTop:25}} id="username" error={!this.state.email_helper_ok} helperText={this.state.email_helper_ok?'':ETVConstants.trans("enter_valid_email_address")} label={ETVConstants.trans("username")} type="email" fullWidth autoFocus required onInput={(e)=>this.setState({email_helper_ok:true})} onChange={(e)=>this.setState({username:e.target.value})}/>
                    <TextField style={{marginTop:20}} id="current_pw1" error={!this.state.cur_pw_helper_ok} helperText={this.state.cur_pw_helper_ok?'':ETVConstants.trans("wrong_password")} label={ETVConstants.trans("current_password")} type="password" fullWidth required onInput={(e)=>this.setState({cur_pw_helper_ok:true})} onChange={(e)=>this.setState({cur_pw:e.target.value})}/>
                    <TextField style={{marginTop:20}} id="new_pw1" error={!this.state.new_pw1_helper_ok} helperText={this.state.new_pw1_helper_ok?'':ETVConstants.trans("enter_valid_password")} label={ETVConstants.trans("new_password")} type="password" fullWidth required onInput={(e)=>this.setState({new_pw1_helper_ok:true})} onChange={(e)=>this.setState({new_pw1:e.target.value})}/>
                    <TextField style={{marginTop:20}} id="new_pw2" error={!this.state.new_pw2_helper_ok} helperText={this.state.new_pw2_helper_ok?'':ETVConstants.trans("password_mismatch")} label={ETVConstants.trans("repeat_password")} type="password" fullWidth required onInput={(e)=>this.setState({new_pw2_helper_ok:true})} onChange={(e)=>this.setState({new_pw2:e.target.value})}/>
                    <Button style={{marginTop:20}} variant="contained" endIcon={<ChangeCircleIcon/>} color="success" fullWidth disabled={this.state.cur_pw.trim().length===0 || this.state.new_pw1.trim().length===0 || this.state.new_pw2.trim().length===0 } onClick={() => this.resetPW()}>{ETVConstants.trans("reset_password")}</Button>
              </DialogContent>
              <DialogActions>
                <Button variant="text"  size="small" color="primary" onClick={() => this.showWindow('reset_password_div',false)}>{ETVConstants.trans("cancel")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.window==='forced_pw_change_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}>
              <DialogTitle id="forced_pw_change">{ETVConstants.trans("please_change_password")}</DialogTitle>
              <DialogContent>
                    <Typography variant='body1'>{ETVConstants.trans("secure_pw_requirements")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_digit")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_lowercase")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_uppercase")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_special_char")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_len_8_20")}</Typography>
                    <Typography variant='body2'>{ETVConstants.trans("pw_expires_183_days")}</Typography>
                    <TextField style={{marginTop:20}} id="new_pw1" error={!this.state.new_pw1_helper_ok} helperText={this.state.error_reason===1?ETVConstants.trans("use_different_password"):this.state.error_reason===2?ETVConstants.trans("not_secure_password"):""} label={ETVConstants.trans("new_password")} type="password" fullWidth required onInput={(e)=>this.setState({new_pw1_helper_ok:true})} onChange={(e)=>this.setState({new_pw1_helper_ok:true, error_reason:0, new_pw1:e.target.value})}/>
                    <TextField style={{marginTop:20}} id="new_pw2" error={!this.state.new_pw2_helper_ok} helperText={this.state.new_pw2_helper_ok?'':ETVConstants.trans("password_mismatch")} label={ETVConstants.trans("repeat_password")} type="password" fullWidth required onInput={(e)=>this.setState({new_pw2_helper_ok:true})} onChange={(e)=>this.setState({new_pw2_helper_ok:true,new_pw2:e.target.value})}/>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" disabled={this.state.new_pw2 !== this.state.new_pw1 || this.state.new_pw1.trim().length===0 } color="secondary" onClick={() => this.enforceNewPw()}>{ETVConstants.trans("save")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.window==='forgot_password_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}>
              <DialogTitle id="forgot_pw_dialog_title">{ETVConstants.trans("send_password_reset_link")}</DialogTitle>
              <DialogContent>
                    <TextField style={{marginTop:20}} id="forgot_pw" error={!this.state.send_email_helper_ok} helperText={this.state.send_email_helper_ok?'':ETVConstants.trans('invalid_email')} label={ETVConstants.trans("username")} type="email" fullWidth autoFocus required onInput={()=>{this.setState({send_email_helper_ok:true})}} onChange={(e)=>this.setState({send_pw_addr:e.target.value})}/>
                    <Button style={{marginTop:15}} disabled={!this.isValidEmail(this.state.send_pw_addr)} variant="contained" endIcon={<EmailIcon/>} fullWidth color="success" onClick={() => this.checkResendPW()}>{ETVConstants.trans("send_password_reset_link")}</Button>                        
              </DialogContent>
              <DialogActions>
                  <Button variant="text" color="primary" onClick={() => this.showWindow('forgot_password_div',false)}>{ETVConstants.trans("cancel")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.window==='contact_div'}
                maxWidth='xs'
                fullWidth={true}
                onClose={()=>{}}>
                <DialogTitle id="forgot_pw_dialog_title">{ETVConstants.trans("contact")}</DialogTitle>
                <DialogContent>
                    <Typography variant='h6'>elevator.tv GmbH</Typography>
                    <Typography variant='body2'>Birkenstrasse 47</Typography>
                    <Typography variant='body2'>CH-6343 Rotkreuz</Typography>
                    <Typography variant='body2'>Switzerland</Typography>
                    <br/><br/>

                    <Typography variant='h6'>elevator.tv Singapore Pte Ltd</Typography>
                    <Typography variant='body2'>20 Collyer Quay, #09-01 </Typography>
                    <Typography variant='body2'>Singapore 049319</Typography>
                    <br/><br/>

                    <Typography variant="body2" sx={{fontWeight:'bold'}}>support@elevator.tv</Typography>

                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={() => this.showWindow('contact_div',false)}>{ETVConstants.trans("ok")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.window==='data_protection_div'}
                maxWidth='lg'
                fullWidth={true}
                onClose={()=>{}}>
                <DialogContent>
                  {ETVConstants.PrivacyText()}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={() => this.showWindow('contact_div',false)}>{ETVConstants.trans("ok")}</Button>
                </DialogActions>
            </Dialog>            

            <Dialog
                open={this.state.window==='terms_condition_div'}
                maxWidth='lg'
                fullWidth={true}
                onClose={()=>{}}>
                <DialogContent>
                  {ETVConstants.TermsAndConditions()}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={() => this.showWindow('contact_div',false)}>{ETVConstants.trans("ok")}</Button>
                </DialogActions>
            </Dialog>              

            <AppBar style={{ zIndex: 1, position: 'fixed', background: '#FFFFFF', height: 50, top: 'auto', bottom: '0' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0 16px' }}>
                  {/* Buttons on the left */}
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button color="primary" onClick={()=>{this.setState({window:'contact_div'})}}>
                      {ETVConstants.trans("contact")}
                    </Button>
                    <Button color="primary" onClick={()=>{this.setState({window:'data_protection_div'})}}>
                      Data Protection
                    </Button>
                    <Button color="primary" onClick={()=>{this.setState({window:'terms_condition_div'})}}>
                      Terms & Conditions
                    </Button>
                  </div>

                  {/* Text on the right */}
                  <span style={{ marginLeft: 'auto', color: '#6e6e6e' }}>
                    <Typography gutterBottom variant="body2" style={{ marginRight: '30px' }}>
                      {this.getAddress(domain)}{" | v" + ETVConstants.VERSION}
                    </Typography>
                  </span>
                </div>
            </AppBar>
        </div>
      );
    }
}

export default Login;
