import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ExpandMore, Face, LockReset, Person, PersonAdd, Redeem, Save, ScreenShare, Security, Stop, Tv, VpnKey } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as ETVConstants from '../ETVConstants.js';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormGroup, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import SelectAvailableScreensNew from '../contentmgmt/SelectAvailableScreensNew.js';

class AdvertisingConfig extends React.Component {

	constructor(props) {
      	super(props);
		this.closeScreenSelection = this.closeScreenSelection.bind(this);
		this.selectedScreens = this.selectedScreens.bind(this);
    }

    state = {
      	isLoaded: false,
		isLoading: false,
		categories:{},
		all_categories_size:0,
		all_devices_length:0,
		list:[],
		checkedCategories:new Set([]),
		chosenChannel:0,
		window:'',
		inv_percentage:2,
		selected_screens:[],
		no_screens:false,
		current_balance:'',
		current_balance_points:0,
		currency:'',
    }

    componentDidMount() {
		this.loadData();
		this.loadBalance();
    }

	loadBalance() {
		if(this.state.isLoading) return;
		this.setState({isLoading:true,isLoaded:false});
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/payment_ui?action=get_balance&user_id="+reqUserID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		  .then( res => res.json() )
		  .then((data) => {
			this.setState({ 
			  current_balance:data.value_in_currency,
			  currency: data.currency,
			  current_balance_points:data.balance,
			});
		  })
		  .catch((error) => {this.setState({current_balance:''})});
	  }


	componentDidUpdate() {
		if(!this.state.isLoaded && !this.state.isLoading) {
			this.loadData();
		}
	}

	showWindow(divID, show) {
		show?this.setState({window:divID}):this.setState({window:''});
	}

    loadData() {
		this.setState({isLoading:true});
		//console.log("----- load users -----");
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/advertising_screen_owner?action=get_config&user_id="+this.props.user_id;
      	console.log("-->urlStr="+urlStr);
		fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
			//console.log("...load data rx="+JSON.stringify(result));
			//load all advertising categories
			var advertising_categories = result.advertising_categories;
			var categories = {};
			for(var i=0; i<advertising_categories.length; i++) {
				var elem = advertising_categories[i];
				var catName = elem.category;
				var arr = categories[catName];
				if(arr) {
					arr.push(elem);
				}else{
					var newArr = [];
					newArr.push(elem);
					categories[catName] = newArr;
				}
			}
			//selected categories
			var allScreens = result.device_ids;
			if(!allScreens || allScreens.length==0) {
				this.setState({no_screens:true,isLoaded:true,isLoading:false});
				return;
			}

			//console.log("existing---->"+JSON.stringify(existing));
			var existing = result.list;
			var checked = new Set([]);
			for(var i=0; i<existing.length; i++) {
				var deviceInfo = existing[i].advertising_category;
				this.addToSet(checked,deviceInfo);
			}
			//console.log("checked="+JSON.stringify(checked));
			var invPercentage = 2;
			var ch = 0;
			if(result.list && result.list.length>0) {
				invPercentage = result.list[0].inverse_percentage;
				ch = result.list[0].channel;
			}

            this.setState({
				categories:categories,
				checkedCategories:checked,
				all_categories_size:advertising_categories.length,
				all_devices_length: result.device_ids.length,
				selected_screens: this.getSelectedDeviceIDs(result.list),
				list: result.list,
				chosenChannel:ch,
				inv_percentage:invPercentage,
              	isLoaded: true,
				isLoading:false,
            });
          },
          (error) => {
            this.setState({
				isLoaded: true,
				isLoading:false,
              	error
            });
          }
        )
    }

	getSelectedDeviceIDs( list) {
		var res = [];
		for(var i=0; i<list.length; i++) {
			res[i] = list[i].device_id;
		}
		return res;
	}

	sendCMDToServer(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({
					isLoaded: false
				})
			})
			.catch((error) => {
				console.error(error);
			});
    }

	showInfoMessage(title,text) {
		this.setState({info_title:title,info_message:text});
		this.showWindow('message_div',true);
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}

	handleItemCheckboxChange( event, catID) {
		//console.log("category="+catID);
		if(event.target.checked) {
			this.addCategory(catID);
		}else{
			this.removeCategory(catID);
		}
	}

	chooseAllCategories(checked) {
		if(!checked) {
			checked = new Set([]);
			this.setState({checkedCategories:checked});
			return;
		}
		var all = this.state.categories;
		//console.log("all------->"+JSON.stringify(all));
		Object.entries(all).forEach( ([key,entryArr]) => {
			for(var i=0; i<entryArr.length; i++) {
				this.addCategory(entryArr[i].id);
			}
		});
	}

	addCategory( catID) {
		var catIDs = this.state.checkedCategories;
		catIDs.add(catID);
		this.setState({checkedCategories:catIDs});
	}

	addCategories( catIDs) {
		var newSet = this.addToSet(this.state.checkedCategories,catIDs);
		this.setState({checkedCategories:newSet});
	}

	addToSet(existingSet, elements) {
		elements.forEach(element => existingSet.add(element));
		return existingSet;
	}

	removeFromSet(existingSet, elements) {
		elements.forEach(element => existingSet.delete(element));
		return existingSet;
	}

	removeCategories( catIDs) {
		var newSet = this.removeFromSet(this.state.checkedCategories,catIDs);
		this.setState({checkedCategories:newSet});
	}

	removeCategory( catID) {
		var catIDs = this.state.checkedCategories;
		catIDs.delete(catID);
		this.setState({checkedCategories:catIDs});
	}

	handleCategoryCheckboxChange( event, catID) {
		var elems = this.state.categories[catID];
		//console.log("---->elems="+elems.length);
		var itemIDs = [];
		for(var i=0; i<elems.length; i++) {
			//console.log("id="+elems[i].id);
			itemIDs.push(elems[i].id);
		}
		if(event.target.checked) {
			this.addCategories(itemIDs);
		}else{
			this.removeCategories(itemIDs);
		}
	}

	isInCategory( id) {
		return this.state.checkedCategories.has(id);
	}

	hasCategoryItems( category) {
		var allElem = this.state.categories[category];
		for(var i=0; i<allElem.length; i++) {
			if(this.isInCategory(allElem[i].id)) return true;
		}
		return false;
	}

	printCategories() {
		return (
			<div>
				{Object.keys(this.state.categories).map((category, index) => (
					<Accordion key={index}>
						<AccordionSummary style={{background:"#f0f0f0"}}
                        	expandIcon={<ExpandMore />}
                        	aria-controls={`panel${index}-content`}
                        	id={`panel${index}-header`}>
							<FormControlLabel
									key={category}
									control={<Checkbox checked={this.hasCategoryItems(category)} onChange={(event) => this.handleCategoryCheckboxChange(event, category)} />}
									label={category}
								/>
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{this.state.categories[category].map((obj, idx) => (
									<FormControlLabel
										key={obj.id}
										control={<Checkbox checked={this.isInCategory(obj.id)} onChange={(event) => this.handleItemCheckboxChange(event, obj.id)} />}
										label={obj.item}
									/>
								))}
							</FormGroup>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		);
	}

	showSelectScreens() {
		this.showWindow("select_screens_div",true);
	}

	closeScreenSelection() {
		//console.log("close screen selected");
		this.setState({window:''});
	}

	selectedScreens( screens) {
		//console.log("selected screens="+screens.length);
		this.setState({selected_screens:screens,window:''});
	}

	saveConfig() {
		var urlStr = ETVConstants.getServerBase()+"/advertising_screen_owner?action=set_config&user_id="+this.props.user_id;
		for(var i=0; i<this.state.selected_screens.length; i++) {
			urlStr+="&device_id="+this.state.selected_screens[i];
		}
		urlStr+="&inv_percentage="+this.state.inv_percentage;
		urlStr+="&channel="+this.state.chosenChannel;
		this.state.checkedCategories.forEach( value => urlStr+="&accepted_advertising_categories="+value);
		//console.log("url="+urlStr);
		this.sendCMDToServer(urlStr);
	}

	removeConfig() {
		this.setState({selected_screens:[],checkedCategories:new Set([])});
		this.saveConfig();
	}

    render() {
		if(this.state.isLoading) {
			return <CircularProgress/>
		}
		if(this.state.no_screens) {
			return <Typography variant='h6'>No ownership of screens</Typography>
		}
      	return(
			<span>
				<Paper elevation={2} sx={{ padding: 2 }}>
				<Typography variant="h6" gutterBottom>Current Credit</Typography>
				<Typography variant="h5" sx={{fontWeight:'bold'}}>{this.state.currency} {(this.state.current_balance / 100).toFixed(2)}</Typography>

				{this.state.current_balance_points > 10000 && (
					<Button
					color="success"
					variant="contained"
					sx={{ marginTop: 2 }}
					onClick={()=>{this.setState({window:'message_div',info_title:'Payout of Funds',info_message:'Please contact your administrator to setup an automatic bank transfer'})}}
					>
					Payout
					</Button>
				)}
				</Paper>

				<Paper elevation={2} sx={{padding:2, marginTop:2, width:600}}>
					<Typography style={{marginTop:20}} variant="h6">Choose Accepted Advertising Categories ({this.state.checkedCategories.size}/{this.state.all_categories_size})</Typography>
					<Typography style={{marginTop:10}} variant="body2">Choose the categories of advertisements that you permit to be displayed on your screens. Consider selecting categories that align with your audience's preferences, match your brand image, and comply with local regulations. This step helps ensure that the content shown on your screens remains relevant, engaging, and appropriate for your target viewers.</Typography>

					<Button sx={{marginTop:2}} onClick={(event)=>{this.showWindow("categories_div",true)}} size="small" variant='contained' startIcon={<Redeem/>}>Accepted Advertising Categories</Button>
					<Divider style={{marginTop:20}}/>	

					<Typography style={{marginTop:20}} variant="h6">Selected Screens for Advertising ({this.state.selected_screens.length}/{this.state.all_devices_length})</Typography>
					<Typography style={{marginTop:10}} variant="body2">Select the specific screens where advertisements will be displayed. Consider factors such as the location, audience demographics, and screen visibility to ensure optimal impact and engagement. Defining which screens are designated for advertising allows you to tailor content for maximum effectiveness while maintaining control over the viewing experience in each environment.</Typography>

					<Button style={{marginTop:10}} variant='contained' startIcon={<Tv/>} size="small" color='primary' onClick={(ev)=>{this.showSelectScreens()}}>Select Screens</Button>

					<Divider style={{marginTop:20}}/>	

					{/*
					<Typography style={{marginTop:20}} variant="h6">Channel to used for Advertising</Typography>
					<FormControl fullWidth variant="outlined" margin="normal">
						<InputLabel id="dropdown-label">{ETVConstants.trans("channel")}</InputLabel>
						<Select
							labelId="dropdown-label"
							id="dropdown"
							disabled={this.state.selected_screens.length==0 || this.state.checkedCategories.size==0}
							value={this.state.chosenChannel}
							onChange={(e)=>this.setState({chosenChannel:e.target.value})}
							label={ETVConstants.trans("channel")} >
							<MenuItem value={0}>Channel 0</MenuItem>
							<MenuItem value={1}>Channel 1</MenuItem>
							<MenuItem value={2}>Channel 2</MenuItem>
							<MenuItem value={3}>Channel 3</MenuItem>
							<MenuItem value={4}>Channel 4</MenuItem>
						</Select>
					</FormControl>

					<Divider style={{marginTop:20}}/>	
					*/}

					<Typography style={{marginTop:20}} variant="h6">Advertising Percentage</Typography>
					<Typography style={{marginTop:10}} variant="body2">Determine the percentage of screen time you wish to allocate for advertising content on your displays. Carefully consider the balance between advertisements and other types of content, such as informational, entertainment, or branded messages, to maintain viewer engagement and avoid oversaturation. Establishing a clear percentage helps ensure that advertisements do not dominate the screen, while still providing ample opportunity to generate revenue or promote relevant offerings.</Typography>

					<FormControl fullWidth variant="outlined" margin="normal">
						<InputLabel>{ETVConstants.trans("max_percentage")}</InputLabel>
						<Select
							id="dropdown2"
							value={this.state.inv_percentage}
							disabled={this.state.selected_screens.length==0 || this.state.checkedCategories.size==0}
							onChange={(e)=>this.setState({inv_percentage:e.target.value})}
							label={ETVConstants.trans("channel")} >
							<MenuItem value={1}>100%</MenuItem>
							<MenuItem value={2}>50%</MenuItem>
							<MenuItem value={3}>33%</MenuItem>
							<MenuItem value={4}>25%</MenuItem>
							<MenuItem value={5}>20%</MenuItem>
							<MenuItem value={10}>10%</MenuItem>
							<MenuItem value={20}>5%</MenuItem>
						</Select>
					</FormControl>

					{/*<Button style={{marginTop:20}} variant="contained" color="error" size="large" onClick={(e)=>{this.removeConfig()}}>{ETVConstants.trans("no_advertising")}</Button>*/}
					<Button disabled={this.state.selected_screens.length==0 || this.state.checkedCategories.size==0} style={{marginTop:20}} variant="contained" color="success" startIcon={<Save/>} onClick={(e)=>{this.saveConfig()}}>{ETVConstants.trans("save")}</Button>

				</Paper>

				<div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
					<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selected={this.state.selected_screens?this.state.selected_screens:[]} selectedScreens={this.selectedScreens}/>
				</div>


				<Dialog
					open={this.state.window==='message_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{this.state.info_title}</DialogTitle>
					<DialogContent>
						<Typography variant="body1">{this.state.info_message}</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='categories_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{this.state.info_title}</DialogTitle>
					<DialogContent>
						<Box display="flex" alignItems="center">
							<Checkbox onClick={(event)=>{this.chooseAllCategories(event.target.checked)}}>choose all</Checkbox>
							<Typography variant="h6" component="div">Categories</Typography>
						</Box>
						{this.printCategories()}
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

			</span>);
	}
}

export default AdvertisingConfig
