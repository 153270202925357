import React from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import LocationMap from './LocationMap';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl,InputLabel,Select,MenuItem,TextField, CardActions, Autocomplete } from '@mui/material';

import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class LocationSelection extends React.Component {

    constructor(props) {
      super(props);
      this.resultListener = this.resultListener.bind(this);
      this.resultListenerCloseWindow = this.resultListenerCloseWindow.bind(this);
      this.setLocationGPS = this.setLocationGPS.bind(this);
    }

    //editing state
    //0 --> read only
    //1 --> insert new
    //2 --> update existing

    state = {
      list:[],
      isLoaded:false,
      editMode:false,
      name:'',
      selected_location_id:0,
      location_type:0,
      location_id:0,
      address:'',
      zip:'',
      city:'',
      country:'',
      contact_phone:'',
      contact_email:'',
      hasChanged:false,
      editing_state:1,
      device_location_changed:false,
    }

    componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadLocations();
      }
		}

    componentDidMount() {
      this.loadLocations();
    }

    loadLocations() {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/xlocation?action=get_locations&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var locID = this.props.cur_location_id;
            this.setState({
              list: this.sortList(result.list),
              selected_location_id:locID,
              isLoaded:true
            });
          },
          (error) => {
            this.setState({
              list: [],
              isLoaded:true,
              error
            });
          }
        )
    }

    sortList( list) {
      var l = list.sort( (a,b) => (a.name.toLowerCase() >= b.name.toLowerCase()? 1:-1))
      return l;
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    getLocation( id) {  
      for(var i=0; i<this.state.list.length; i++) {
        if(this.state.list[i].location_id==id) return this.state.list[i];
      }
      return null;
    }

    selectLocationID(locID) {
      if(locID<0) {
        this.setState({editing_state:1,selected_location_id:-1,device_location_changed:false});
        return;
      }
      var deviceLocationHasChanged = (locID>0 && locID!==this.props.cur_location_id);
      var loc = this.getLocation(locID);
      var locType = loc.location_type;
      if(!locType) {
        locType=0;
      }
      this.setState({editing_state:2,device_location_changed:deviceLocationHasChanged,selected_location_id:locID,name:loc.name,location_type:locType,address:loc.address,zip:loc.zip,city:loc.city,country:loc.country,phone:loc.phone,email:loc.email,lat:loc.latitude,lgt:loc.longitude});
    }

    setLocationGPS( lat, lgt) {
      var window = this.state.editing_state==1?'new_location_div':"edit_location_div";
      this.setState({lat:lat,lgt:lgt,window:window});
    }

    createLocationDropDown() {
        return(
            <FormControl fullWidth style={{marginTop:20}}>
            <InputLabel>{ETVConstants.trans("registered_locations")}</InputLabel>
            <Select
                label={ETVConstants.trans("registered_locations")}
                id="registered_locations"
                fullWidth
                value={this.state.selected_location_id}
                onChange={(e)=>{this.selectLocationID(e.target.value)}}>
                    {this.state.list.map( (loc) => {return <MenuItem key={loc.location_id} value={loc.location_id}>{loc.name}-{loc.address}</MenuItem>})}
            </Select>
          </FormControl>
        )
    }

    createCountryDropDown() {
      const countries = [
        "United States",
        "United Kingdom",
        "Canada",
        "Australia",
        "Germany",
        "France",
        "India",
        "China",
        "Japan",
        "Singapore",
        "Netherlands",
        "South Korea",
        "Sweden",
        "Italy",
        "Spain",
        "Brazil",
        "Mexico",
        "Switzerland",
        "South Africa",
        "United Arab Emirates",
        "New Zealand",
        "Denmark",
        "Norway",
        "Finland",
        "Hong Kong",
        "Argentina",
        "Poland",
        "Indonesia",
        "Turkey",
        "Russia",
        "Saudi Arabia",
        "Ireland",
        "Philippines",
        "Thailand",
        "Malaysia",
        "Vietnam",
        "Chile",
        "Colombia",
        "Israel",
        "Portugal",
        "Austria",
        "Belgium",
        "Czech Republic",
        "Hungary",
        "Luxembourg",
      ];
    
      return (
        <Autocomplete
          disableClearable
          options={countries}
          value={this.state.country || ""}
          onChange={(event, newValue) => {
            this.setState({ hasChanged: true, country: newValue });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              style={{ marginTop: 15 }}
              size="small"
              variant="outlined"
              label={ETVConstants.trans("country")}
            />
          )}
          disabled={this.state.editing_state === 0}
        />
      );
    }
    

    createLocationTypeDropDown() {
      return(
          <FormControl fullWidth style={{marginTop:20}}>
          <InputLabel>{ETVConstants.trans("location_type")}</InputLabel>
          <Select
              label={ETVConstants.trans("location_type")}
              id="location_type"
              fullWidth
              required
              disabled={this.state.editing_state==0}
              value={this.state.location_type}
              onChange={(e)=>{this.setState({location_type:e.target.value})}}>
                  <MenuItem key='l0' value={0}>{ETVConstants.trans("undefined")}</MenuItem>
                  <MenuItem key='l100' value={100}>{ETVConstants.trans("location_type_hotel_5_star")}</MenuItem>
                  <MenuItem key='l101' value={101}>{ETVConstants.trans("location_type_hotel_4_star")}</MenuItem>
                  <MenuItem key='l102' value={102}>{ETVConstants.trans("location_type_hotel_3_star")}</MenuItem>
                  <MenuItem key='l103' value={103}>{ETVConstants.trans("location_type_hotel_others")}</MenuItem>
                  <MenuItem key='l200' value={200}>{ETVConstants.trans("location_type_mall_luxury")}</MenuItem>
                  <MenuItem key='l201' value={201}>{ETVConstants.trans("location_type_mall_standard")}</MenuItem>
                  <MenuItem key='l300' value={300}>{ETVConstants.trans("location_type_office_high_rise")}</MenuItem>
                  <MenuItem key='l301' value={301}>{ETVConstants.trans("location_type_office_mid_rise")}</MenuItem>
                  <MenuItem key='l302' value={302}>{ETVConstants.trans("location_type_office_low_rise")}</MenuItem>
                  <MenuItem key='l400' value={400}>{ETVConstants.trans("location_type_residential_condo")}</MenuItem>
                  <MenuItem key='l401' value={401}>{ETVConstants.trans("location_type_residential_hdb")}</MenuItem>
                  <MenuItem key='l501' value={501}>{ETVConstants.trans("location_type_restaurant")}</MenuItem>
                  <MenuItem key='l502' value={502}>{ETVConstants.trans("location_type_cafe")}</MenuItem>
          </Select>
        </FormControl>
      )
  }

    isDisabled() {
      if (this.state.selected_location_id<0) return true;

    }

    resultListener(val) {
      this.setState({isLoaded:false, editing_state:0, selected_location_id:-1,window:''});
    }

    resultListenerCloseWindow(val) {
      this.props.closeHook();
    }

    insertNewLocation() {
        var url = ETVConstants.getServerBase()+"/xlocation?action=register_location&user_id="+this.props.user_id;
        url += "&location_type="+this.state.location_type;
        url += "&name="+encodeURIComponent(this.state.name);
        url += "&address="+encodeURIComponent(this.state.address);
        url += "&zip="+encodeURIComponent(this.state.zip);
        url += "&city="+encodeURIComponent(this.state.city);
        url += "&country="+encodeURIComponent(this.state.country);
        url += "&contact_phone="+encodeURIComponent(this.state.contact_phone);
        url += "&contact_email="+encodeURIComponent(this.state.contact_email);
        url += "&longitude="+encodeURIComponent(this.state.lgt);
        url += "&latitude="+encodeURIComponent(this.state.lat);
        ETVConstants.sendCMDToServerListener(url,this.resultListener);
    }

    updateLocation() {
      var url = ETVConstants.getServerBase()+"/xlocation?action=update_location&user_id="+this.props.user_id;
      url += "&location_id="+this.state.selected_location_id;
      url += "&location_type="+this.state.location_type;
      url += "&name="+encodeURIComponent(this.state.name);
      url += "&address="+encodeURIComponent(this.state.address);
      url += "&zip="+encodeURIComponent(this.state.zip);
      url += "&city="+encodeURIComponent(this.state.city);
      url += "&country="+encodeURIComponent(this.state.country);
      url += "&contact_phone="+encodeURIComponent(this.state.contact_phone);
      url += "&contact_email="+encodeURIComponent(this.state.contact_email);
      url += "&longitude="+encodeURIComponent(this.state.lgt);
      url += "&latitude="+encodeURIComponent(this.state.lat);
      ETVConstants.sendCMDToServerListener(url,this.resultListener);
  }

    setLocation() {
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_location_id&user_id="+this.props.user_id+"&device_id="+this.props.device_id;
      url += "&location_id="+this.state.selected_location_id;
      ETVConstants.sendCMDToServerListener(url,this.resultListenerCloseWindow);
    }

    canSaveNewLocation() {
      if(this.state.location_type<0) return false;
      if(!this.state.name || this.state.name.trim() === '') return false;
      if(!this.state.address || this.state.address.trim() === '') return false;
      if(!this.state.zip || this.state.zip.trim() === '') return false;
      if(!this.state.city || this.state.city.trim() === '') return false;
      if(!this.state.country || this.state.country.trim() === '') return false;
      if(!this.state.contact_phone || this.state.contact_phone.trim() === '') return false;
      if(!this.state.contact_email || this.state.contact_email.trim() === '') return false;
      if(!this.state.lat || this.state.lat==0) return false;
      if(!this.state.lgt || this.state.lgt==0) return false;
      return true;
    }

    showEditLocationDiv() {
      var loc = this.getLocation(this.state.selected_location_id);
      var locType = 0;
      var name = '';
      var addr = '';
      var zip = '';
      var city = '';
      var country = '';
      var phone = '';
      var email = '';
      var lat = '';
      var lgt = '';
      if(loc) {
        locType = loc.location_type;
        name = loc.name;
        addr = loc.address;
        zip = loc.zip;
        city = loc.city;
        country = loc.country;
        phone = loc.contact_phone;
        email = loc.contact_email;
        lat = loc.latitude;
        lgt = loc.longitude;
      }
      this.setState({window:'edit_location_div',editing_state:2,location_type:locType,name:name,address:addr,zip:zip,city:city,country:country,contact_phone:phone,contact_email:email,lat:lat,lgt:lgt});
    }

    showInsertNewLocationDiv() {
      var locType = 0;
      var name = '';
      var addr = '';
      var zip = '';
      var city = '';
      var country = '';
      var phone = '';
      var email = '';
      var lat = '';
      var lgt = '';
      this.setState({window:'new_location_div',editing_state:2,location_type:locType,name:name,address:addr,zip:zip,city:city,country:country,contact_phone:phone,contact_email:email,lat:lat,lgt:lgt});
    }


    render() {

      return (
        <div>
          <Card>
            <CardContent>
                {this.createLocationDropDown()}
            </CardContent>
            <CardActions>
                {this.state.selected_location_id>=0?
                  <span>
                    <Button size='small' variant="contained" onClick={()=>{this.showEditLocationDiv()}}>{ETVConstants.trans("edit_location")}</Button>
                    <Button style={{marginLeft:10}} size='small' variant="contained" onClick={()=>{this.showInsertNewLocationDiv()}}>{ETVConstants.trans("insert_new_location")}</Button><br/>
                    <Button style={{marginTop:20}} disabled={!this.state.device_location_changed} startIcon={<Save/>} size='medium' color='success' variant="contained" onClick={()=>{this.setLocation()}}>{ETVConstants.trans("set_location")}</Button>
                  </span>
                  :
                  <span/>
                }  
            </CardActions>
          </Card>

          <Dialog
              open={this.state.window==='edit_location_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{this.setState({window:''})}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
              <DialogContent>
                    {this.createLocationTypeDropDown()}
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, name:e.target.value})}} label={ETVConstants.trans("location_name")} value={this.state.name} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, address:e.target.value})}} label={ETVConstants.trans("address")} value={this.state.address} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, zip:e.target.value})}}  label={ETVConstants.trans("zip")} value={this.state.zip} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, city:e.target.value})}}  label={ETVConstants.trans("city")} value={this.state.city} />
                    {this.createCountryDropDown()}
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_phone:e.target.value})}}  label={ETVConstants.trans("phone")} value={this.state.contact_phone} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_email:e.target.value})}}  label={ETVConstants.trans("email")} value={this.state.contact_email} />
                    <Button style={{marginTop:20}} disabled={this.state.editing_state==0} size='small' variant='contained' color='primary' onClick={()=>this.setState({window:"location_map_div",editing_state:2})}>{ETVConstants.trans("set_gps")}</Button><br/>
                    <span>
                        <TextField required style={{marginTop:15}} disabled={true} size='small' variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lat:e.target.value})}} value={this.state.lat} />
                        <TextField required style={{marginTop:15}} disabled={true} size='small' variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lgt:e.target.value})}} value={this.state.lgt} />
                    </span>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
                <Button size='small' disabled={!this.canSaveNewLocation()} startIcon={<Save/>} variant="contained" color='success' onClick={()=>{this.updateLocation()}}>{ETVConstants.trans("update_location")}</Button>
              </DialogActions>
          </Dialog>



          <Dialog
              open={this.state.window==='new_location_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{this.setState({window:''})}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
              <DialogContent>
                    {this.createLocationTypeDropDown()}
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, name:e.target.value})}} label={ETVConstants.trans("location_name")} value={this.state.name} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, address:e.target.value})}} label={ETVConstants.trans("address")} value={this.state.address} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, zip:e.target.value})}}  label={ETVConstants.trans("zip")} value={this.state.zip} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, city:e.target.value})}}  label={ETVConstants.trans("city")} value={this.state.city} />
                    {this.createCountryDropDown()}
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_phone:e.target.value})}}  label={ETVConstants.trans("phone")} value={this.state.contact_phone} />
                    <TextField required style={{marginTop:15}} disabled={this.state.editing_state==0} fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_email:e.target.value})}}  label={ETVConstants.trans("email")} value={this.state.contact_email} />
                    <Button style={{marginTop:20}} disabled={this.state.editing_state==0} size='small' variant='contained' color='primary' onClick={()=>this.setState({window:"location_map_div",editing_state:1})}>{ETVConstants.trans("set_gps")}</Button><br/>
                    <span>
                        <TextField required style={{marginTop:15}} disabled={true} size='small' variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lat:e.target.value})}} value={this.state.lat} />
                        <TextField required style={{marginTop:15}} disabled={true} size='small' variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lgt:e.target.value})}} value={this.state.lgt} />
                    </span>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
                <Button size='small' disabled={!this.canSaveNewLocation()} startIcon={<Save/>} variant="contained" color='success' onClick={()=>{this.insertNewLocation()}}>{ETVConstants.trans("insert_new_location")}</Button>
              </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.window==='location_map_div'}
            onClose={()=>{}}
            fullWidth={true}
            maxWidth='xl'
            scroll='paper'
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_location")}</DialogTitle>
            <DialogContent>
              <div style={{height:800,maxHeight:800}}>
                {this.state.window==='location_map_div'?<LocationMap lat={this.state.lat} lgt={this.state.lgt} setLocationGPS={this.setLocationGPS} />:<span/>}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({window:this.state.editing_state==1?'new_location_div':'edit_location_div'})}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
            </DialogActions>
				</Dialog>


        </div>
      )
    }
}

export default LocationSelection;
