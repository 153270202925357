import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage} from 'react-intl';
import { Box, Typography } from '@mui/material';
// front end version

export const VERSION = "3.1.0";

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const SERVER_ADDRESS = "elevatortvbackend.appspot.com";

//export const SERVER_ADDRESS = "/backend3-1";
//this one only for development
//export const SERVER_ADDRESS = "http://192.168.1.102/backend3-1";

export const ON_PREMISE_VERSION = false;
export const WHATSAPP_BIZ_NR = "6598307109";


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function trans(msgID) {
  return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
}

export function getServerBase() {
    if(ON_PREMISE_VERSION) {
      //return "http://"+SERVER_ADDRESS;
      return SERVER_ADDRESS; //"/backend3-1"; // this will be like /te
    }else{
      return "https://"+SERVER_ADDRESS;
    }
}

export const EMAIL_REGEX =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
export function isEmail( emailAddr) {
  return EMAIL_REGEX.test(emailAddr);
}

export const PHONE_REGEX =  /^\+\s*[0-9\s*]{7,}$/;
export function isPhone( phone) {
  // remarks: ^abc$ --> means exact match
  // "/" start and "/" end tags
  // \s* as many whitespace as wishes
  // {7,} at least 7 numbers
  // \+ must start with +
  return PHONE_REGEX.test(phone);
}

export function compareDevice( a, b) {
  //console.log("localcompare "+a.device_name);
  //console.log("localcompare "+b.device_name);
  return a.device_name.localeCompare(b.device_name);
}

export function compareGroup( a, b) {
  return a.group_name.localeCompare(b.group_name);
}

export function filterOutEmptyGroups( groups) {
  var res = [];
  for(var i=0; i<groups.length; i++) {
      if(groups[i].device_ids.length>0) res.push(groups[i]);
  }
  return res;
}

export function sendCMDToServer(url) {
  var accessToken = localStorage.getItem("etv.access_token");
  var reqUserID = localStorage.getItem("etv.user_id");
  fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"]) {
          //console.log("ok");
        }else{
          console.log("result not ok="+JSON.stringify(result));
        }
      },
      (error) => {
        console.log("error="+error);
      }
    )
}

export function sendCMDToServerListener(url, resultListener) {
  var accessToken = localStorage.getItem("etv.access_token");
  var reqUserID = localStorage.getItem("etv.user_id");
  fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    .then(res => res.json())
    .then(
      (result) => {
        resultListener(result);
      },
      (error) => {
        console.log("error="+error);
      }
    )
}

function getTimeAgoVal( mins) {
  var val = mins;
  if(val<60) return Math.round(val)+" minutes ago";
  val = val/60;
  if(val<24) return Math.round(val)+" hours ago";
  val = val/24;
  if(val<31) return Math.round(val)+" days ago";
  val = val/31;
  if(val<12) return Math.round(val)+" months ago";
  val = Math.round(val/12);
  if(val===1) {
    return Math.round(val)+" year ago";
  }else{
    return Math.round(val)+" years ago";
  }
}

export function getLastConnectInfo(time) {
  var d = new Date(time.replace(/-/g, "/"));
  var diffMins = getLastConnectMins(time);
  var msg = d.toLocaleString();
  if(diffMins<10) {
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#67f00c' }}  /></Tooltip>

  }else if(diffMins<60) {
      //less than 1 hour
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f5d247' }}  /></Tooltip>

  }else if(diffMins<1440) {
      //less than 1 day
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f59e07' }}  /></Tooltip>

  }else if(diffMins<10080) {
        //less than 1 week
        return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f56c11' }}  /></Tooltip>

  }else{
     //less than 1 week
     return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#96080c' }}  /></Tooltip>
  }
}

export function getLastConnectSymbol(time, startup, status) {

  var d = new Date(time.replace(/-/g, "/"));
  var diffMins = getLastConnectMins(time);
  var msg = d.toLocaleString();
  if(startup && startup.trim().length>0) {
    var startupDt = new Date(startup);
    msg = getTimeAgoVal(diffMins)+" (login: "+startupDt.toLocaleString()+")";
  }
  if(status==="offline") {
    return <Tooltip title={"(offline) "+msg} ><FiberManualRecordIcon size='large' style={{ color: '#fa2d33' }}  /></Tooltip>
  }
  //console.log("diffMins="+diffMins);
  if(diffMins<10) {
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#67f00c' }}  /></Tooltip>

  }else if(diffMins<60) {
      //less than 1 hour
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f5d247' }}  /></Tooltip>

  }else if(diffMins<1440) {
      //less than 1 day
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f59e07' }}  /></Tooltip>

  }else if(diffMins<10080) {
        //less than 1 week
        return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f56c11' }}  /></Tooltip>

  }else{
     //less than 1 week
     return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#96080c' }}  /></Tooltip>
  }
}

export function getLastConnectMins( time) {
  // careful on IOS! --> use ".replace(..)"
  var d = new Date(time.replace(/-/g, "/"));
  var dNow = Date.now();
  var diffMins = (dNow - d)/60000;
  return diffMins;
}

////////////////////////////////////////////////////////////////////////////////

export function makeLayoutURLRelative(url) {
  if(url.indexOf("/contents")>0) {
    // it is a custom layout (stored in the individual's user directory)
    return makeContentURLRelative(url);
  }
  return url.substring(url.indexOf("/layouts"))
}

export function makeContentURLRelative(url) {
  return url.substring(url.indexOf("/contents"))
}

export function isAlphaNumeric( val) {
  return val.match(/^\w+$/) || val.length===0
}


////////////////////////////////////////////////////////////////////////////////


export function createTimeHoursMin(hours, minutes) {
    // Get the current day using dayjs()
    const now = dayjs();
    // Set the hours and minutes to the given values
    const updatedTime = now.hour(hours).minute(minutes).second(0); // Optionally reset seconds to 0
    // Return the updated Day.js object
    return updatedTime;
}

export function createDateNow() {
    var now = dayjs();
    return now;
}

export function createDate(etvDate) {
    const dayjsDate = dayjs(etvDate,'YYYY-MM-DD');
    return dayjsDate;
}

export function createTime(etvTime) {
    const [hours, minutes] = etvTime.split(':').map(Number);
    // Validate that both hours and minutes are numbers
    if (isNaN(hours) || isNaN(minutes)) {
        throw new Error('Invalid time format. Use "HH:mm"');
    }
    const dayjsObject = dayjs().hour(hours).minute(minutes).second(0);
    return dayjsObject;
}

export function createDateYearsPlus(yearsFromNow) {
    // Get the current date
    const now = dayjs();
        // Add one year to the current date
    const yearsLater = now.add(yearsFromNow, 'year');
    // Return the new Day.js object
    return yearsLater;
}

export function oneYearLater() {
    return createDateYearsPlus(1);
}

export function forever() {
  return createDateYearsPlus(30);
}

export function formatDate( date2) {
    if(date2.isValid()) {
        return date2.format('YYYY-MM-DD')
    }
    return null;
}

export function formatTime( date2) {
    if(date2.isValid()) {
        return date2.format('HH:mm');
    }
    return null;
}

export function PrivacyText() {
  return(
  <Box>
    <Typography variant="h5" gutterBottom>
      Privacy and Data Protection Policy
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      Effective Date: 1.1.2025
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      Last Updated: 1.1.2025
    </Typography>

    <Typography variant="body1" paragraph>
      At <b>elevator.tv</b>, your privacy and data protection are of utmost importance to us. 
      This policy outlines how we collect, use, store, and protect your personal data in accordance with 
      industry standards and applicable laws.
    </Typography>

    <Typography variant="h6" gutterBottom>
      1. Data We Collect
    </Typography>
    <Typography variant="body1" paragraph>
      We may collect the following types of personal information:
    </Typography>
    <Typography component="ul">
      <li>Contact Information: Name, email address, phone number, company details.</li>
      <li>Account Information: Username, password, preferences.</li>
      <li>Transaction Information: Details of purchases or service subscriptions.</li>
      <li>Usage Information: Data related to how you use our website, products, and services.</li>
      <li>Technical Information: IP address, browser type, and device information.</li>
    </Typography>

    <Typography variant="h6" gutterBottom>
      2. How We Use Your Data
    </Typography>
    <Typography variant="body1" paragraph>
      We use your data to:
    </Typography>
    <Typography component="ul">
      <li>Provide and improve our products and services.</li>
      <li>Manage your account and process transactions.</li>
      <li>Communicate with you about updates, promotions, and support.</li>
      <li>Ensure compliance with legal obligations.</li>
      <li>Analyze website usage to enhance user experience.</li>
    </Typography>

    <Typography variant="h6" gutterBottom>
      3. Sharing Your Data
    </Typography>
    <Typography variant="body1" paragraph>
      We do not sell your data. However, we may share it with:
    </Typography>
    <Typography component="ul">
      <li>Service Providers assisting with our services.</li>
      <li>Authorities to comply with legal obligations.</li>
      <li>Third parties, only with your consent.</li>
    </Typography>

    <Typography variant="h6" gutterBottom>
      4. Data Storage and Retention
    </Typography>
    <Typography variant="body1" paragraph>
      Your data is securely stored and retained only as long as necessary to fulfill the outlined purposes or comply with legal requirements.
    </Typography>

    <Typography variant="h6" gutterBottom>
      5. Your Rights
    </Typography>
    <Typography variant="body1" paragraph>
      You have rights to access, correct, delete, or restrict your data. You can also withdraw consent or request a copy of your data.
    </Typography>

    <Typography variant="h6" gutterBottom>
      6. Cookies and Tracking
    </Typography>
    <Typography variant="body1" paragraph>
      We use cookies to enhance your experience. Manage cookies via your browser settings.
    </Typography>

    <Typography variant="h6" gutterBottom>
      7. Security Measures
    </Typography>
    <Typography variant="body1" paragraph>
      We employ encryption, access controls, and regular security assessments to safeguard your data.
    </Typography>

    <Typography variant="h6" gutterBottom>
      8. International Data Transfers
    </Typography>
    <Typography variant="body1" paragraph>
      Your data may be transferred outside your region with safeguards in place to ensure protection.
    </Typography>

    <Typography variant="h6" gutterBottom>
      9. Updates to This Policy
    </Typography>
    <Typography variant="body1" paragraph>
      We may update this policy. Check this page periodically for changes.
    </Typography>

    <Typography variant="h6" gutterBottom>
      10. Contact Us
    </Typography>
    <Typography variant="body1" paragraph>
      For inquiries or concerns, reach us at:
    </Typography>
    <Typography variant="body2">
      <b>elevator.tv GmbH</b>
    </Typography>
    <Typography variant="body2">Email: support@elevator.tv</Typography>
    <Typography variant="body2">Address: elevator.tv GmbH, Birkenstrasse 47, CH-6343 Rotkreuz, Switzerland </Typography>
  </Box>
  )
}

export function TermsAndConditions() {
return (
  <Box>
    <Typography variant="h5" gutterBottom>
      Terms and Conditions
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      Effective Date: 1.1.2025
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      Last Updated: 1.1.2025
    </Typography>

    <Typography variant="body1" paragraph>
      Welcome to <b>elevator.tv</b>. These terms and conditions outline the rules and regulations for using our website, products, and services. By accessing or using our services, you agree to comply with these terms.
    </Typography>

    <Typography variant="h6" gutterBottom>
      1. Acceptance of Terms
    </Typography>
    <Typography variant="body1" paragraph>
      By accessing or using our services, you accept and agree to be bound by these terms. If you do not agree with any part of these terms, you must not use our services.
    </Typography>

    <Typography variant="h6" gutterBottom>
      2. Use of Our Services
    </Typography>
    <Typography variant="body1" paragraph>
      You agree to use our services only for lawful purposes and in a way that does not infringe on the rights of others or restrict their use and enjoyment. Prohibited actions include:
    </Typography>
    <Typography component="ul">
      <li>Violating any laws or regulations.</li>
      <li>Uploading or sharing inappropriate multimedia content, including but not limited to explicit, obscene, or offensive material.</li>
      <li>Harming, harassing, or causing distress to others.</li>
      <li>Uploading or distributing malicious software.</li>
      <li>Engaging in unauthorized access or hacking.</li>
    </Typography>

    <Typography variant="h6" gutterBottom>
      3. Account Responsibilities
    </Typography>
    <Typography variant="body1" paragraph>
      You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account. Notify us immediately of any unauthorized use.
    </Typography>

    <Typography variant="h6" gutterBottom>
      4. Intellectual Property
    </Typography>
    <Typography variant="body1" paragraph>
      All content, trademarks, and materials on our services are the property of <b>elevator.tv GmbH</b>. You may not reproduce, distribute, or use any materials without prior written consent.
    </Typography>

    <Typography variant="h6" gutterBottom>
      5. Payments and Refunds
    </Typography>
    <Typography variant="body1" paragraph>
      All payments for services are final, except as outlined in our refund policy. It is your responsibility to ensure payment details are accurate and up to date.
    </Typography>

    <Typography variant="h6" gutterBottom>
      6. Limitation of Liability
    </Typography>
    <Typography variant="body1" paragraph>
      To the extent permitted by law, <b>elevator.tv GmbH</b> will not be liable for any indirect, incidental, or consequential damages arising from your use of our services.
    </Typography>

    <Typography variant="h6" gutterBottom>
      7. Termination
    </Typography>
    <Typography variant="body1" paragraph>
      We reserve the right to suspend or terminate your access to our services at our sole discretion, without notice, for conduct that violates these terms or is harmful to our interests.
    </Typography>

    <Typography variant="h6" gutterBottom>
      8. Governing Law
    </Typography>
    <Typography variant="body1" paragraph>
      These terms are governed by and construed in accordance with the laws of Switzerland. Any disputes will be resolved exclusively in Swiss courts.
    </Typography>

    <Typography variant="h6" gutterBottom>
      9. Changes to These Terms
    </Typography>
    <Typography variant="body1" paragraph>
      We may update these terms periodically. Continued use of our services indicates acceptance of any changes.
    </Typography>

    <Typography variant="h6" gutterBottom>
      10. Contact Us
    </Typography>
    <Typography variant="body1" paragraph>
      If you have questions or concerns about these terms, please contact us at:
    </Typography>
    <Typography variant="body2">
      <b>elevator.tv</b>
    </Typography>
    <Typography variant="body2">Email: support@elevator.tv</Typography>
    <Typography variant="body2">Address: elevator.tv GmbH, Birkenstrasse 47, CH-6343 Rotkreuz, Switzerland</Typography>
  </Box>
)
};

